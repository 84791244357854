:root {
  --red: #ff3939; 
  --green:  #178f2b;
  --yellow: #fcc168;
}


.App {
  text-align: center;

  margin-left: 5%;
  margin-right: 5%;
}

body {
  width: calc(100vw - 34px);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.log-feed {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.log-container {
  display: flex;
  border-width: 1px;
  border-style: solid;
  margin-bottom: 10px;
  height:150px;
  width:100%;
  justify-content: center;
}

.log-container:hover {
  background-color: #ffe3f9;
  cursor: pointer;
}

.expanded {
  height:500px;
}

.expanded:hover {
  background-color: white;
}

.log {
  height:440px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.log-metadata {
  margin-top: auto;
  margin-bottom: auto;
  flex-direction: column;
}

.log-button-row {
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom:5px;
  margin-top:5px;
}

.log-button {
  border-width: 1px;
  border-style: solid;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  background-color: #bb6fab;
  color:white;
  width: 80px;
  margin-left: 10px;
}

.log-line {

}

.even {
  background-color: #ebebeb;
}

.warning {
  background-color: #fcc168;
}

.error {
  background-color: #ff8282;
}

.green {
  color: var(--green);
}

.red {
  color: var(--red);
}

.yellow {
  color: var(--yellow);
}

.border-red {
  border-color: var(--red);
}

.border-green {
  border-color: var(--green);
}

.border-yellow {
  border-color: var(--yellow);
}

.bg-red {
  background-color: var(--red);
}

.bg-green {
  background-color: var(--green);
}

.bg-yellow {
  background-color: var(--yellow);
}

.log-dirs-container {
  display:flex;
  align-items: center;
  justify-content: center;
}

.log-dir-tile {
  width:150px;
  height:150px;
  border-style: solid;
  margin-left: 10px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.log-dir-tile:hover {
  background-color: #ffe3f9;
  cursor: pointer;
}

.dir-path-container {
  /* border-style: solid; */
  display:flex;
  flex-direction: 'row';
  align-items: center;
  justify-content: center;
}

.dir-path-button {
  /* border-style: solid; */
  border-radius: 25px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
}

.dir-path-button:hover {
  background-color: #ffe3f9;
  cursor: pointer;
}